import { WidgetDashboardModels } from "../../../../models/components/widgets"
import { WidgetDashboard } from "../../../componentes/widgets/WidgetDashboard"


const dummyValues:WidgetDashboardModels.WidgetDashboardOptions[] = [{
    className:"card-xl-stretch mb-xl-8",
    color:"primary",
    title:"Geral",
    current:{
            headerOption:{
            title:"Total",
            progress:0,
            total:30000,
            color:"primary"
        },
        itens:[
            {
                title:"Serviços",
                progress:66,
                total:20000,
                color:"primary"
            },
            {
                title:"Productos",
                progress:33,
                total:10000,
                color:"primary"
            },
            {
                title:"Descontos",
                progress:1,
                total:300,
                color:"primary"
            },
            {
                title:"Descontos App",
                progress:0.1,
                total:30,
                color:"primary"
            }
        ]
    }, 
    last:{
        headerOption:{
            title:"Total",
            progress:0,
            total:50000,
            color:"primary"
        },
        itens:[
            {
                title:"Serviços",
                progress:80,
                total:40000,
                color:"primary"
            },
            {
                title:"Productos",
                progress:20,
                total:10000,
                color:"primary"
            }
        ]
    }
},
{
    className:"card-xl-stretch mb-xl-8",
    color:"success",
    title:"Serviços Grupo",
    current:{
            headerOption:{
            title:"Total",
            progress:0,
            total:30000,
            color:"success"
        },
        itens:[
            {
                title:"Cabeleireiro",
                progress:66,
                total:20000,
                color:"success"
            },
            {
                title:"Estetica",
                progress:33,
                total:10000,
                color:"success"
            },
            {
                title:"Makeup",
                progress:1,
                total:300,
                color:"success"
            },
            {
                title:"Manicure e Pedicure",
                progress:0.1,
                total:30,
                color:"success"
            }
        ]
    }, 
    last:{
        headerOption:{
            title:"Total",
            progress:0,
            total:50000,
            color:"success"
        },
        itens:[
            {
                title:"Cabeleireiro",
                progress:80,
                total:40000,
                color:"success"
            },
            {
                title:"Estetica",
                progress:20,
                total:10000,
                color:"success"
            },
            {
                title:"Makeup",
                progress:20,
                total:10000,
                color:"success"
            },
            {
                title:"Manicure e Pedicure",
                progress:0.1,
                total:30,
                color:"success"
            }
        ]
    }
},
{
    className:"card-xl-stretch mb-xl-8",
    color:"danger",
    title:"Funcionarios",
    current:{
            headerOption:{
            title:"Total",
            progress:0,
            total:30000,
            color:"danger"
        },
        itens:[
            {
                title:"Pedro",
                progress:66,
                total:20000,
                color:"danger"
            },
            {
                title:"João",
                progress:33,
                total:10000,
                color:"danger"
            },
            {
                title:"Maria",
                progress:1,
                total:300,
                color:"danger"
            },
            {
                title:"Antonio",
                progress:0.1,
                total:30,
                color:"danger"
            },
            {
                title:"Joaquim",
                progress:1,
                total:300,
                color:"danger"
            },
        ]
    }, 
    last:{
        headerOption:{
            title:"Total",
            progress:0,
            total:50000,
            color:"danger"
        },
        itens:[
            {
                title:"João",
                progress:80,
                total:40000,
                color:"danger"
            },
            {
                title:"Maria",
                progress:20,
                total:10000,
                color:"danger"
            },
            {
                title:"Pedro",
                progress:20,
                total:10000,
                color:"danger"
            },
            {
                title:"Teresa",
                progress:0.1,
                total:30,
                color:"danger"
            },
            {
                title:"Antonio",
                progress:0.1,
                total:30,
                color:"danger"
            }
        ]
    }
}]


const Statistics = () => {
    
    return (
        <>
         <div className='row mt-10'>
            {dummyValues.map((item,index)=>{
                return  <div className="col-4" key={`${index}`}>
                    <WidgetDashboard 
                        className={item.className} 
                        color={item.color} 
                        title={item.title} 
                        current={item.current}
                        last={item.last}/>
                </div>
            })
            }
            </div>
        </>
    )
}

export {Statistics}