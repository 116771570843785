import {Space, SpaceGroup} from '../../spaces/spaces-list/core/_models'

const SPACE_LOCAL_STORAGE_KEY = 'kt-space-react-v'
const SPACE_GROUP_LOCAL_STORAGE_KEY = 'kt-space-group-react-v'
const getSpace = (): Space | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(SPACE_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const Space: Space = JSON.parse(lsValue) as Space
    if (Space) {
      // You can easily check Space_token expiration also
      return Space
    }
  } catch (error) {
    console.error('Space LOCAL STORAGE PARSE ERROR', error)
  }
}
const setSpace = (Space: Space) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(Space)
    localStorage.setItem(SPACE_LOCAL_STORAGE_KEY, lsValue)
    if (!window.location.href.includes('auth'))
      window.location.href = '/dashboardSpace'
  } catch (error) {
    console.error('Space LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeSpace = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(SPACE_LOCAL_STORAGE_KEY)
    if (!window.location.href.includes('auth') && window.location.pathname !== '/dashboardSpaceGroup')
      window.location.href = '/dashboardSpaceGroup'
  } catch (error) {
    console.error('Space LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getSpaceGroup = (): SpaceGroup | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(SPACE_GROUP_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const Space: SpaceGroup = JSON.parse(lsValue) as SpaceGroup
    if (Space) {
      // You can easily check Space_token expiration also
      return Space
    }
  } catch (error) {
    console.error('Space Group LOCAL STORAGE PARSE ERROR', error)
  }
}
const setSpaceGroup = (Space: SpaceGroup) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(Space)
    localStorage.setItem(SPACE_GROUP_LOCAL_STORAGE_KEY, lsValue)
    if (!window.location.href.includes('auth'))
      window.location.href = '/dashboardSpaceGroup'
  } catch (error) {
    console.error('Space Group LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeSpaceGroup = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(SPACE_GROUP_LOCAL_STORAGE_KEY)
    if (!window.location.href.includes('auth') && window.location.pathname !== '/dashboard')
      window.location.href = '/dashboard'
  } catch (error) {
    console.error('Space Group LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getSpace,
  setSpace,
  removeSpace,
  getSpaceGroup,
  setSpaceGroup,
  removeSpaceGroup,
  SPACE_LOCAL_STORAGE_KEY,
}
