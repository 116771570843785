import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import { Statistics } from '../dashboardSpaceGroup/Components/Statistics'

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  const { currentSpace} = useAuth()
  return (
     <>
      <span className='fs-1 text-primary'>{currentSpace?.name}</span>
      {/* <ActionsRequired /> */}
      <Statistics />
    </>
  )
}

const DashboardSpaceWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardSpaceWrapper}
