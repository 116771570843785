
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { ActionsRequired } from './Components/ActionRequired'
import { Statistics } from './Components/Statistics'




const DashboardPage = () => {
  const {currentSpaceGroup} = useAuth()
 
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <span className='fs-1 text-primary'>Grupo {currentSpaceGroup?.name}</span>
      <ActionsRequired />
      <Statistics />
    </>
  )
}

const DashboardSpaceGroupWrapper = () => {
  const intl = useIntl()
  const {currentSpaceGroup} = useAuth()

  useEffect(() => {
    if(!currentSpaceGroup)
      window.location.href = "/dashboard"
  }, [currentSpaceGroup])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardSpaceGroupWrapper}
