import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {EnumRoles, useAuth} from '../../../../app/modules/auth'
import { useSpaceGroup } from '../../../../app/hooks/useSpaceGroup'

export function AsideMenuSpace() {
  const {currentUser} = useAuth()
  const {spacesInGroup} = useSpaceGroup()
  return (
    <>
      <AsideMenuItem
        to='/spaces'
        title='Dashboard'
        fontIcon='bi-speedometer fs-2'
        bsTitle='Dashboard'
        className='py-2'
      />
      {currentUser && 
        currentUser.roles && 
        currentUser.roles.filter(
          (x) => x === EnumRoles.Estatisticas ||
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos 
        ) && spacesInGroup && spacesInGroup.length > 1 &&
        <AsideMenuItem to='/statistics' title='Estatisticas' fontIcon='bi-percent fs-2' className='py-2' />}
      
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/billing', '/Caixa']}
            title='Faturação'
            fontIcon='bi-wallet fs-2'
          >
            <AsideMenuItem to='/billing' title='Faturar' bsTitle='Faturar' hasBullet={true} />
            <AsideMenuItem to='/Caixa' title='Caixa' bsTitle='Caixa' hasBullet={true} />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/clients', '/historyTemplates', '/Segments']}
            title='Clientes'
            fontIcon='bi-people fs-2'
          >
            <AsideMenuItem
              to='/clients'
              title='Lista Clientes'
              bsTitle='Lista Clientes'
              hasBullet={true}
            />
            {currentUser &&
              currentUser.roles &&
              currentUser?.roles?.filter(
                (x) =>
                  x === EnumRoles.Administrador ||
                  x === EnumRoles.AdministradorEspacos ||
                  x === EnumRoles.Marketing ||
                  x === EnumRoles.FaturacaoGerente
              ).length > 0 && (
                <AsideMenuItem
                  to='/historyTemplates'
                  title='Templates de Históricos'
                  bsTitle='Templates de Históricos'
                  hasBullet={true}
                />
              )}
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={[
              '/galleriesPage',
              '/editSpace',
              '/Country',
              '/editSocialMediaSpace',
              '/SpaceTranslationsCountries',
            ]}
            title='Espaço'
            fontIcon='bi-pencil fs-2'
          >
            <AsideMenuItem to='/editSpace' title='Editar' bsTitle='Editar' hasBullet={true} />
            <AsideMenuItem
              to='/editSocialMediaSpace'
              title='Social Media'
              bsTitle='Social media'
              hasBullet={true}
            />
            <AsideMenuItem to='/Country' title='Paises' bsTitle='Paises' hasBullet={true} />
            <AsideMenuItem
              to='/SpaceTranslationsCountries'
              title='Idiomas'
              bsTitle='Idiomas'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {spacesInGroup && spacesInGroup.length > 1 && currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Vouchers ||
            x === EnumRoles.Codes ||
            x === EnumRoles.Campanhas ||
            x === EnumRoles.Emails ||
            x === EnumRoles.Sms ||
            x === EnumRoles.Notificacoes
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/vouchers', '/codes', '/Codes/Offers', '/Codes/configs', '/NotificationsTemplate', 
              '/campaigns', '/Campaigns/CampaignsTypes', '/EmailSended', '/EmailConfigs', '/SmsTemplates']}
            title='Marketing'
            fontIcon='bi-speedometer fs-2'
          >
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/EmailSended', '/EmailConfigs']}
              title='Emails'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem
                to='/EmailSended'
                title='Histórico de envio de emails'
                bsTitle='Histórico de envio de emails'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/EmailConfigs'
                title='Configs Email'
                bsTitle='Configs Email'
                hasBullet={true}
              />
            </AsideMenuItemWithSubMain>
          </AsideMenuItemWithSubMain>
        )}

      <AsideMenuItem
        to='/sairEspaco'
        title='Voltar'
        fontIcon='bi-caret-left-fill fs-4'
        bsTitle='Voltar'
        className='py-2'
        isLogOutSpace={true}
      />
    </>
  )
}
