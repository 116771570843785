/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { WidgetDashboardModels } from '../../../models/components/widgets'
import { AdapterConverNumberToCurrency } from '../../../adapters/AdapterConverNumberToCurrency'



const WidgetDashboard: React.FC<WidgetDashboardModels.WidgetDashboardOptions> = ({className, color, title, current, last}) => {

  const [dataSelected, setdataSelected] = useState<WidgetDashboardModels.dataRange>(WidgetDashboardModels.dataRange.current)
  const [data, setData] = useState<WidgetDashboardModels.WidgetDashboardOptionsStata>(current)

  useEffect(() => {
    setData(dataSelected === WidgetDashboardModels.dataRange.current ? current : last)
  }, [dataSelected])
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-light-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
            <div className='ms-1'>
              {/* begin::Menu */}
              <button
                type='button'
                className={`btn btn-sm ${dataSelected === WidgetDashboardModels.dataRange.lastMonth && `btn-${color}`} border-0 me-3`}
                onClick={()=> setdataSelected(WidgetDashboardModels.dataRange.lastMonth)}
              >
                Anterior
              </button>
              <button
                type='button'
                className={`btn btn-sm ${dataSelected === WidgetDashboardModels.dataRange.current && `btn-${color}`} border-0 me-n3`}
                onClick={()=> setdataSelected(WidgetDashboardModels.dataRange.current)}
              >
                Currente
              </button>
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{data.headerOption.title}</span>
            <span className='fw-bold fs-2x pt-1'>{AdapterConverNumberToCurrency(data.headerOption.total) }</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {data.itens.map((x, index) => {
            return <Item title={x.title} progress={x.progress} color={x.color} total={x.total} key={`dashboardGraph${title}_${index}`} />
          })}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const Item = ({title,progress, total, color}:WidgetDashboardModels.ItemWidgetDashboard) =>{
  return <>{/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Description */}
            <div className=' ms-5 d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1 me-5'>
                <span className='fs-5 text-gray-800 fw-bold'>
                  {title} - {progress} %
                </span>
                <div className='progress h-5px w-100 mt-3'>
                  <div
                    className={`progress-bar bg-${color}`}
                    role='progressbar'
                    style={{width: `${progress}%`}}
                  ></div>
                </div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{AdapterConverNumberToCurrency(total)}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}</>
}

export {WidgetDashboard}
