import React, {createContext, useEffect, useState} from 'react'
import { useAuth} from '../../auth'
import { EmployeeWithSchedules, FilterOptionsModel, ScheduleForAppointment } from '../../statistics/Core/models'
import { getAllEmployesBySpaceIdWithSchedules, getEmployeesInSpace, getGroupsInSpace, getSectionsInSpace, getSpaceSchedules } from './_requests'

export type SpaceContextType = {
  employesInSpace: FilterOptionsModel[]
  setEmployesInSpace: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  sectionsInSpace: FilterOptionsModel[]
  setSectionsInSpace: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  groupsInSpace: FilterOptionsModel[]
  setGroupsInSpace: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  spaceSchedules: ScheduleForAppointment[]
  setSpaceSchedules: React.Dispatch<React.SetStateAction<ScheduleForAppointment[]>>
  employeesSchedules: EmployeeWithSchedules[]
  setEmployeesSchedules: React.Dispatch<React.SetStateAction<EmployeeWithSchedules[]>>
}

export const SpaceContext = createContext<SpaceContextType | undefined>(undefined)

interface SpaceProviderProps {
  children: React.ReactNode
}

const SpaceProvider: React.FC<SpaceProviderProps> = ({children}) => {
  const {currentSpace} = useAuth()
  const [spacesInSpace, setSpacesInSpace] = useState<FilterOptionsModel[]>([])
  const [employesInSpace, setEmployesInSpace] = useState<FilterOptionsModel[]>([])
  const [sectionsInSpace, setSectionsInSpace] = useState<FilterOptionsModel[]>([])
  const [groupsInSpace, setGroupsInSpace] = useState<FilterOptionsModel[]>([])
  const [spaceSchedules, setSpaceSchedules] = useState<ScheduleForAppointment[]>([])
  const [employeesSchedules, setEmployeesSchedules] = useState<EmployeeWithSchedules[]>([])
  
  useEffect(() => {
    if(currentSpace && currentSpace.spaceId){
      getEmployeesInSpace(currentSpace?.spaceId).then((x)=>{
        if(x.data)
          setEmployesInSpace(x.data)
      })
      getAllEmployesBySpaceIdWithSchedules(currentSpace.spaceId).then((x)=>{
        if(x.data)
          setEmployeesSchedules(x.data)
      })
      getSectionsInSpace(currentSpace?.spaceId).then((x)=>{
        if(x.data)
          setSectionsInSpace(x.data)
      })
      getGroupsInSpace(currentSpace?.spaceId).then((x)=>{
        if(x.data)
          setGroupsInSpace(x.data)
      })
      getSpaceSchedules(currentSpace?.spaceId).then((x)=>{
        if(x.data)
          setSpaceSchedules(x.data)
      })
    }else {
      setEmployesInSpace([])
      setSectionsInSpace([])
      setGroupsInSpace([])
      setSpaceSchedules([])
    }
    
  }, [currentSpace])
  

  return (
    <SpaceContext.Provider
      value={{
        spacesInSpace,
        setSpacesInSpace,
        employesInSpace,
        setEmployesInSpace,
        sectionsInSpace,
        setSectionsInSpace,
        groupsInSpace,
        setGroupsInSpace,
        spaceSchedules, 
        setSpaceSchedules,
        employeesSchedules,
        setEmployeesSchedules
      } as SpaceContextType}
    >
      {children}
    </SpaceContext.Provider>
  )
}

export default SpaceProvider
