export const AdapterApiGetWayProxyResponseToObject = (resp) => {
  const {data} = resp
  const statusCode = data.statusCode
  const bodyResp = JSON.parse(data.body)
  const response = {
    ...resp,
    status: statusCode,
    data: bodyResp?.result ? bodyResp.result : null,
    errors: bodyResp?.errors,
  }
  return response
}
