
export type WidgetDashboardOptions = {
  className: string
  color: string
  title: string
  current: WidgetDashboardOptionsStata
  last: WidgetDashboardOptionsStata
}

export type WidgetDashboardOptionsStata = {
  headerOption: ItemWidgetDashboard
  itens: ItemWidgetDashboard[]
}

export type ItemWidgetDashboard = {
  title: string;
  progress: number;
  total: number;
  color:string;
}


export enum dataRange  {
  current = 0,
  lastMonth = 1
}