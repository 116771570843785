import { useSearchParams } from "react-router-dom";

import '../../../../assets/cancelPublicity.scss';
import { useEffect, useState } from "react";
import { removeClientFromPublicity } from "../core/_requests";
import { Loading } from "../../../componentes/form/Loading";


export function CancelPublicity() {
  const [searchParams] = useSearchParams();
  const [loading, setloading] = useState<boolean>(true)
  const [status, setStatus] = useState<"success" | "error" | undefined>(undefined);

  useEffect(() => {
    const spaceGroupId = searchParams.get("spaceGroupId");
    const clientId = searchParams.get("clientId");
    removeClientFromPublicity({spaceGroupId, clientId})
    .then((result)=> {
      if(result)
        setStatus("success")
      else
        setStatus("error")

        setloading(false)
    }).catch(() => {
      setloading(true)
      setStatus("error")
    })
  }, [])
  

  return (
    <div className="container  d-flex align-items-center justify-content-center">
      <div className="row">
        <div className="col-12 mt-5">
            {status === "success" && <span className="fs-1 text-success weight-700">Removido da lista de publicidade com sucesso</span>}
            {status === "error" && <span className="fs-1 text-danger weight-700">Ocurreu um erro ao remover da lista de publicidade, entre en contacto com o espaço</span>}
            {loading && <Loading />}
          </div>
      </div>
    </div>
  )
}
