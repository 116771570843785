import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, EnumRoles, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import * as spaceHelper from './SpaceHelpers'
import {getUserByToken, refreshToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {Space, SpaceGroup} from '../../spaces/spaces-list/core/_models'
import {getCountriesBySpace} from '../../country/components/country/core/_requests'
import { getEditFormSpacesGroupById } from '../../spaces/edit-space/Core/_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  handleCurrentUser: (user: UserModel | undefined) => void
  logout: () => void
  currentSpaceGroup: SpaceGroup | undefined
  saveSpaceGroup: (space: SpaceGroup | undefined) => void
  currentSpace: Space | undefined
  saveSpace: (space: Space | undefined) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  currentSpaceGroup: spaceHelper.getSpaceGroup(),
  currentSpace: spaceHelper.getSpace(),
  saveSpace: () => {},
  saveSpaceGroup: () => {},
  handleCurrentUser: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentSpaceGroup, setCurrentSpaceGroup] = useState<SpaceGroup | undefined>(
    spaceHelper.getSpaceGroup()
  )
  const [currentSpace, setCurrentSpace] = useState<Space | undefined>(spaceHelper.getSpace())

  const handleCurrentUser = useCallback(
    async (user: UserModel | undefined) => {
      if(user){
        if(user.roles && user.roles.filter(x=>x === EnumRoles.Administrador).length === 0  ){
          if(user.userSpaces && user.userSpaces.length === 1){
            if(user.userSpaces && user.userSpaces.length === 1 && currentSpaceGroup?.spaceGroupId !== user.userSpaces[0].spaceGroupId){
              const spaceGroupId = user.userSpaces[0].spaceGroupId;
              const result = await getEditFormSpacesGroupById(spaceGroupId)
              if(result)
                saveSpaceGroup({
                  spaceGroupId:result.spacesGroupsId,
                  name:result.name } as SpaceGroup)
              else 
                saveSpaceGroup({
                  spaceGroupId:spaceGroupId } as SpaceGroup)

            }
          }
        }
        
      }
      setCurrentUser(user);
    },
    [currentSpaceGroup],
  )
  

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const saveSpaceGroup = async (space: SpaceGroup | undefined) => {
    if (space) {
      setCurrentSpaceGroup(space)
      spaceHelper.setSpaceGroup(space)
    } else {
      spaceHelper.removeSpaceGroup()
    }
  }

  const saveSpace = async (space: Space | undefined) => {
    if (space) {
      let spaceTemp = {...space} as Space
      if (spaceTemp) {
        const countries = await getCountriesBySpace(
          'items_per_page=999&page=1&active=true&spaceId=' + space?.spaceId
        )
        if (countries?.data) spaceTemp.countries = countries.data
      }
      setCurrentSpace(spaceTemp)
      spaceHelper.setSpace(spaceTemp)
    } else {
      spaceHelper.removeSpace()
    }
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    if(currentSpaceGroup)
      saveSpaceGroup(undefined)
    if(currentSpace)
      saveSpace(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        logout,
        currentSpace,
        saveSpace,
        currentSpaceGroup,
        saveSpaceGroup,
        handleCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
function hasPassed30Minutes(startTimestampInSeconds:number) {
  const startTime = new Date(startTimestampInSeconds * 1000); // Convert to milliseconds
  const currentTime = new Date(); // Get current date and time
  const elapsedTime = currentTime.getTime() - startTime.getTime(); // Calculate elapsed time in milliseconds

  const thirtyMinutesInMilliseconds = 30 * 60 * 1000; // 30 minutes = 30 * 60 seconds * 1000 milliseconds

  if (elapsedTime > thirtyMinutesInMilliseconds) {
    return true
  } else {
    return false
  }
}
const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, saveAuth, logout, handleCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current && auth) {
          const payloadBase64 = auth.api_token.split('.')[1];
    
          // Decode payload (Base64 decoding)
          const decodedPayload = JSON.parse(atob(payloadBase64));

          if('iat' in decodedPayload){
            if(hasPassed30Minutes(decodedPayload.iat)){
              const {data} = await refreshToken();
              saveAuth(data)
            }
          }
          const {data} = await getUserByToken()
          if (data) {
            await handleCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
