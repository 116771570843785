import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { AdapterApiGetWayProxyResponseToObject } from '../adapters/AdapterApiGetWayProxyResponseToObject';
import { getAuth } from '../app/modules/auth';

interface CustomAxiosRequestHeaders extends AxiosRequestHeaders {
  'Access-Control-Allow-Origin': string;
  'Access-Control-Allow-Methods': string;
  'Connection': string;
  'Authorization': string;
}


export interface AxiosResponseCustom<T> extends AxiosResponse {
  errors?:MSError[]
}

export interface MSError
{
    value?:string
    code?:string
}

export const instance = axios.create();

instance.interceptors.request.use((request) => {
  const auth = getAuth()
    request.headers = {
      'Access-Control-Allow-Origin': "*",
      'Access-Control-Allow-Methods': "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      'Connection': "keep-alive",
      'Authorization': auth?.api_token && `Bearer ${auth?.api_token}`
    } as CustomAxiosRequestHeaders;
    return request;
});

instance.interceptors.response.use(function (response) {
    return AdapterApiGetWayProxyResponseToObject(response);
});