/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { WidgetTakeActionModels } from '../../../models/components/widgets'

const WidgetTakeAction: React.FC<WidgetTakeActionModels.WidgetTakeActionOptions> = ({className, progress, title, subTitle,itens}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {subTitle && <span className='text-muted fw-semibold fs-7'>{subTitle}</span>}          
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
       {/* begin:Stats */}
        <div className='d-flex flex-column w-100 '>
          <span className='text-dark me-2 fw-bold pb-3'>Progress - {progress}%</span>

          <div className='progress h-5px w-100'>
            <div
              className={`progress-bar bg-${progress < 80 ? 'danger' : progress < 99 ? 'warning': 'success'}`}
              role='progressbar'
              style={{width: `${progress}%`}}
            ></div>
          </div>
        </div>
        {/* end:Stats */}
        {itens && 
          <>
            {/* begin::Items */}
            <div className='mt-12'>
              {itens.map((item, index)=> {
                return <ItemRequired 
                  key={`requiredItem_${index}`}
                  title={item.title} 
                  subTitle={item.subTitle} 
                  url={item.url}
                  btnColor={item.btnColor}/>
              })}
            </div>
            {/* end::Items */}
          </>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}



const ItemRequired = ({title,subTitle,url,btnColor}:WidgetTakeActionModels.ItemWidgetAction) =>{
  return <>{/* begin::Item */}
          <div className='d-flex flex-stack mt-3'>
            {/* begin::Section */}
            <div className='d-flex align-items-center me-2'>
              {/* begin::Title */}
              <div className='py-3'>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                  {title}
                </a>
                {subTitle && <div className='fs-7 text-muted fw-semibold mt-1'>{subTitle}</div>}
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}
            {url && 
            <>
              {/* begin::Label */}
              <a href={url} className={`btn btn-${btnColor}`}>
                  Resolver
                </a>
              {/* end::Label */}
            </>}
          </div>
          {/* end::Item */}</>
}
export {WidgetTakeAction}
