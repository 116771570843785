import React, { useEffect, useState } from "react"
import { WidgetTakeAction } from "../../../componentes/widgets/WidgetTakeAction"
import { useAuth } from "../../../modules/auth"
import { ActionMetaBase } from "react-select"
import { WidgetTakeActionModels } from "../../../../models/components/widgets"
import { getRequiredActionsSpaceGroupCampaigns, getRequiredActionsSpaceGroupInfo, getRequiredActionsSpaceGroupRecomendationCode } from "../Core/_requests"
interface Actions {
  cols: string[];
  items: WidgetTakeActionModels.WidgetTakeActionOptions[];
}

const calculateBootstrapColumns = (items: WidgetTakeActionModels.WidgetTakeActionOptions[]): Actions[] => {
  const rows: Actions[] = [];
  const itemsPerRow = 3; // Máximo de 3 itens por linha

  let i = 0;

  while (i < items.length) {
    const remainingItems = items.length - i;

    // Verificar quantos itens devem ser incluídos na linha
    let itemsInRow: number;

    if(remainingItems === 4){
      itemsInRow = 2; // Preenche com 2 itens
    } else if (remainingItems >= 3) {
      itemsInRow = 3; // Preenche com 3 itens
    } else if (remainingItems === 2) {
      itemsInRow = 2; // Preenche com 2 itens
    } else {
      itemsInRow = 1; // Preenche com 1 item
    }

    // Criar as colunas
    const cols = Array(itemsInRow).fill(`col-${12 / itemsInRow}`);

    rows.push({
      cols,
      items: items.slice(i, i + itemsInRow)
    });

    i += itemsInRow; // Avançar pelo número de itens na linha
  }

  return rows;
};


const ActionsRequired = () => {
    const {currentSpaceGroup} = useAuth()
    const [actionsRequired, setActionsRequired] = useState<Actions[] | undefined>(undefined)
    const [allActions, setAllActions] = useState<WidgetTakeActionModels.WidgetTakeActionOptions[]>([])
    
    useEffect(() => {
        if(currentSpaceGroup && currentSpaceGroup.spaceGroupId){
            getRequiredActionsSpaceGroupInfo(currentSpaceGroup?.spaceGroupId).then(x=>{
                if(x && x.actions)
                    setAllActions(itens => [...itens, ...x.actions]);
            })
            getRequiredActionsSpaceGroupCampaigns(currentSpaceGroup?.spaceGroupId).then(x=>{
                if(x && x.actions)
                    setAllActions(itens => [...itens, ...x.actions]);
            })
            getRequiredActionsSpaceGroupRecomendationCode(currentSpaceGroup?.spaceGroupId).then(x=>{
                if(x && x.actions)
                    setAllActions(itens => [...itens, ...x.actions]);
            })
        }
    }, [])

    useEffect(() => {
        if(allActions)
        setActionsRequired(calculateBootstrapColumns(allActions))
    }, [allActions])

    if(allActions.length === 0) return<></>
    
    return (
        <>
            {actionsRequired && 
                <div className='accordion mt-10' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                        <button
                            className='accordion-button fs-4 fw-bold'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_1_body_1'
                            aria-expanded='false'
                            aria-controls='kt_accordion_1_body_1'
                        >
                            Ações necesarias
                        </button>
                        </h2>
                        <div
                        id='kt_accordion_1_body_1'
                        className='accordion-collapse collapse show'
                        aria-labelledby='kt_accordion_1_header_1'
                        data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body bg-light'>
                                {actionsRequired.map((row, rowIndex) => (
                                    <React.Fragment key={`row_${rowIndex}`}>
                                        {/* Adiciona o separador a partir da segunda linha */}
                                        {rowIndex > 0 && <div className="separator my-3"></div>}
                                        {/* Renderiza a linha */}
                                        <div className="row">
                                            {row.items.map((item, itemIndex) => (
                                            <div key={`widgetActions_${itemIndex}`} className={row.cols[itemIndex]}>
                                                <WidgetTakeAction
                                                title={item.title}
                                                className={item.className}
                                                progress={item.progress}
                                                itens={item.itens}
                                                />
                                            </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>  
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export {ActionsRequired}