import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const {currentUser, currentSpace, currentSpaceGroup} = useAuth()
  return currentSpaceGroup && currentSpace ? (
    <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
      {currentSpaceGroup?.name} - {currentSpace.name}
    </h1>
  ) : currentSpaceGroup ? (
    <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
      {currentSpaceGroup.name}
    </h1>
  ) : (
    <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>{`${currentUser?.firstName} ${currentUser?.lastName}`}</h1>
  )
}
